import { DEFAULT_RATIO, Ratio, isRatio } from '@canalplus/mycanal-commons';
import { Placeholder } from '@canalplus/mycanal-sharedcomponent';
import { useStore as useStoreOneNavigation } from '@canalplus/one-navigation';
import { SafetyCodeContext } from '@canalplus/types-acm';
import { ApiV2LiveGridFeature } from '@dce-front/hodor-types/api/v2/live_grid/definitions';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadableAdultDisclaimerConnected from '../../../components/AdultDisclaimer/LoadableAdultDisclaimerConnected';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import LiveGridPerso from '../../../components/LiveGrid/LiveGridPerso/LiveGridPerso';
import { MetaTagsTemplate } from '../../../components/MetaTags/MetaTagsTemplate';
import TriggerVerticalScroll from '../../../components/TriggerVerticalScroll/TriggerVerticalScroll';
import { DEFAULT_PLACEHOLDER_NUMBER } from '../../../constants/common';
import { DATA_BINDER_GABARIT_LIST_TAB } from '../../../constants/gabaritList';
import { LIVE_GRID_CHANNELS_TO_FETCH } from '../../../constants/liveGrid';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import useInfiniteQueryTemplate from '../../../helpers/hooks/useInfiniteQueryTemplate/useInfiniteQueryTemplate';
import { useIntersectionObserver } from '../../../helpers/hooks/useIntersectionObserver';
import { useIsTvDevice } from '../../../helpers/hooks/useIsTvDevice';
import { formatLiveGridUrl } from '../../../helpers/liveTV/liveTV-helper';
import I18n from '../../../lang';
import { FetchDetails, FetchRequestTypes } from '../../../services/types';
import { resetAdultBrowsing } from '../../../store/slices/adult';
import { adultBrowsingSelector } from '../../../store/slices/adult-selectors';
import {
  isPlayerScriptLoadedSelector,
  requestIdSelector,
} from '../../../store/slices/application-selectors';
import {
  accessibleEpgIDsSelector,
  areAccessibleChannelsLoadedSelector,
  profileIdSelector,
} from '../../../store/slices/user-selectors';
import { getLiveGridNextPageUrl } from '../data/getLiveGridNextPageUrl';
import { ILiveGridState } from '../data/types';
import styles from './LiveGridContainer.css';
import LiveGridTemplate from './LiveGridTemplate';

export type LiveGridContainerProps = {
  displayPlaceholderChannels?: boolean;
  isPerso?: boolean;
  onClickParameters?: FetchDetails['onClickParameters'];
  url?: string;
};

function LiveGridContainer({
  displayPlaceholderChannels,
  isPerso,
  onClickParameters,
  url = '',
}: LiveGridContainerProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const dispatch = useAppDispatch();
  const isPlayerLoaded = useSelector(isPlayerScriptLoadedSelector);
  const profileId = useSelector(profileIdSelector);
  const requestId = useSelector(requestIdSelector);
  const isTvDevice = useIsTvDevice();
  const isAdultBrowsing = useSelector(adultBrowsingSelector);
  const epgIDs = useSelector(accessibleEpgIDsSelector);
  const areAccessibleChannelsLoaded = useSelector(
    areAccessibleChannelsLoadedSelector
  );
  const imageRatio = isRatio(DEFAULT_RATIO) ? DEFAULT_RATIO : Ratio.Ratio169;
  const nbPlaceholderItems = DEFAULT_PLACEHOLDER_NUMBER;

  const formattedUrl = formatLiveGridUrl(url, epgIDs);

  const isFetchEnabled = !!url && isPlayerLoaded && areAccessibleChannelsLoaded;

  const [
    {
      isLoading,
      isFetching,
      isFetchingNextPage,
      error,
      refetch: refetchLiveGridRequest,
      fetchNextPage,
      hasNextPage,
      data,
    },
  ] = useInfiniteQueryTemplate<ILiveGridState>(
    formattedUrl,
    {
      options: { requestId, profileId, isPerso },
      onClickParameters,
      template: FetchRequestTypes.LiveGrid,
    },
    {
      // we have to wait the load of player and the epgIds
      // (If we don't wait for the player, on first load, due to latency to get epgIDs we first fetch '[url]' then '[url]?epgIDs', it provokes a loading with placeholder, then channels, then placeholder then channels.)
      // Now we load placeholders then channels and do only one Hodor call
      enabled: isFetchEnabled,
      getNextPageParam: (lastPageData) =>
        getLiveGridNextPageUrl(
          lastPageData,
          epgIDs,
          LIVE_GRID_CHANNELS_TO_FETCH
        ),
    }
  );

  // Flatten channels and features in data pages
  const { channels = [], features } =
    data?.pages.reduce<{
      channels?: ApiV2BroadcastChannel[];
      features?: ApiV2LiveGridFeature[];
    }>(
      (acc, current) => ({
        channels: acc.channels?.concat(current.channels),
        features: current.features && acc.features?.concat(current.features),
      }),
      {
        channels: [],
        features: [],
      }
    ) || {};

  const { context, isAdult, tracking } = data?.pages?.[0] || {};
  const showAdultDisclaimer = isAdult && !isAdultBrowsing;
  const hasChannel = Boolean(channels.length);
  const isEmptyState = !showAdultDisclaimer && !hasChannel;
  const showContent = !showAdultDisclaimer && hasChannel;
  const store = useStoreOneNavigation();

  const { refCallback: loadMoreButtonRef } = useIntersectionObserver({
    isEnabled: hasNextPage && !isFetchingNextPage,
    onIntersect: fetchNextPage,
  });

  useEffect(() => {
    if (!isAdult && isAdultBrowsing) {
      dispatch(resetAdultBrowsing());
    }
  }, [isAdult, dispatch, isAdultBrowsing]);

  // Used to properly focus Tabs when accessing page OD or not
  useEffect(() => {
    if (isEmptyState && !store.current) {
      const layer = store.getActiveLayer();
      const tabsBinder = layer?.binders.find(
        (binder) =>
          binder.el.getAttribute('data-binder') === DATA_BINDER_GABARIT_LIST_TAB
      );
      layer?.focusDefault(tabsBinder);
    }
  }, [isEmptyState, store]);

  if ((isLoading || isFetching || !isFetchEnabled) && !channels.length) {
    return (
      <section className={styles.LiveGridContainer}>
        <Placeholder
          nbPlaceholder={nbPlaceholderItems}
          ratio={imageRatio}
          imageSize={isTvDevice ? 'large' : 'normal'}
        />
      </section>
    );
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  return (
    <div className={styles.LiveGridContainer}>
      <MetaTagsTemplate data={data} enableAlternateLinksUpdate />
      {showAdultDisclaimer && (
        <LoadableAdultDisclaimerConnected
          safetyCodeContext={SafetyCodeContext.Parental}
          isLiveGrid
        />
      )}
      {showContent && (
        <LiveGridTemplate
          channels={channels}
          context={context}
          displayPlaceholderChannels={displayPlaceholderChannels}
          features={features}
          isAdult={isAdult}
          isPerso={isPerso}
          isTvDevice={isTvDevice}
          ratio={imageRatio}
          nbPlaceholderItems={nbPlaceholderItems}
          refetchLiveGridRequest={refetchLiveGridRequest}
        />
      )}

      {!isPerso && isEmptyState && !isFetching && (
        <ErrorMessage>{t('ErrorMessages.noContent')}</ErrorMessage>
      )}

      {isPerso && (
        <LiveGridPerso
          isEmptyState={isEmptyState}
          isTvDevice={isTvDevice}
          refetchLiveGridRequest={refetchLiveGridRequest}
          liveGridPageTracking={tracking}
        />
      )}
      <TriggerVerticalScroll ref={loadMoreButtonRef} />
    </div>
  );
}

export default LiveGridContainer;
