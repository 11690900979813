import { Ratio } from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import { getRatioClassname } from '../../helpers/getRatioClassname';
import styles from './Placeholder.module.css';

export type PlaceholderItemProps = {
  ratio: Ratio;
  isFromDetail?: boolean;
};

export function PlaceHolderItem({
  ratio,
  isFromDetail = false,
}: PlaceholderItemProps): JSX.Element {
  return (
    <li
      className={classNames(styles.placeholder__ratio, {
        [styles['placeholder__ratio--grid']]: !isFromDetail, // Temp, actually we have only grid with new rework of placeholders
        [styles['placeholder__ratio--row']]: false,
        [styles['placeholder__ratio--detailV5']]: isFromDetail,
      })}
      data-testid="placeholder-item"
    >
      <div
        className={classNames(
          styles.placeholder__content,
          getRatioClassname(ratio)
        )}
      />
    </li>
  );
}
